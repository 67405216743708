<template>
	<div style="background: #eee">
		<van-sticky>
			<van-nav-bar title="入职申请" />
		</van-sticky>
		<van-overlay :show="showLoading">
			<div class="wrapper" @click.stop>
				<van-loading size="24px" vertical>上传中...</van-loading>
			</div>
		</van-overlay>
		<van-form @submit="onSubmit">
			<div class="content">
				<van-field
					v-model="userEntryAddParams.name"
					label="姓名"
					clearable
					placeholder="请填写姓名"
					required
					:rules="[{ required: true }]"
				/>
				<van-field
					v-model="userEntryAddParams.telephone"
					label="手机号码"
					placeholder="请填写手机号码"
					clearable
					required
					validate-trigger="onBlur"
					type="tel"
					:rules="[{ required: true, validator: this.validatePhone }]"
				/>
				<van-field
					v-model="userEntryAddParams.idCard"
					label="身份证号"
					clearable
					required
					placeholder="请填写身份证号"
					@blur="idCardChange"
					:rules="[{ required: true, validator: this.validateIdCard }]"
				/>
				<van-field
					v-model="userEntryAddParams.age"
					label="年龄"
					type="digit"
					required
					placeholder="请填写年龄"
					:rules="[{ required: true }]"
				/>
				<van-field
					readonly
					clickable
					required
					v-model="userEntryAddParams.sex"
					label="性别"
					placeholder="点击选择性别"
					@click="showSex = true"
					:rules="[{ required: true }]"
				/>
				<van-field
					v-model="userEntryAddParams.jobStr"
					label="岗位"
					clearable
					placeholder="请填写岗位"
					required
					readonly
					@click="showJob = true"
					:rules="[{ required: true }]"
				/>
				<van-field
					v-model="userEntryAddParams.electricianLicense"
					label="电工证"
					placeholder="请填写电工证号"
					v-show="userEntryAddParams.job === '0'"
				/>
				<van-field
					:value="formatTime(userEntryAddParams.electricianExpDate)"
					clickable
					label="电工证到期日"
					placeholder="请选择电工证到期日"
					readonly
					@click="
						showTime = true;
						currentDate = new Date();
					"
					v-show="userEntryAddParams.job === '0'"
				/>
				<van-field
					v-model="userEntryAddParams.address"
					label="现居住地址"
					placeholder="请填写现居住地址"
					required
					:rules="[{ required: true, message: '请填写现居住地址' }]"
				/>
				<van-field
					name="uploader"
					label="身份证人像面照片"
					required
					:rules="[{ required: true, message: '请上传身份证人像面照片' }]"
				>
					<template #input>
						<van-uploader
							v-model="userEntryImage.idCardFrontImageUploader"
							:before-read="imageUpload"
							@click-upload="flagUpload = true"
							name="idCardFrontImageUploader"
							:before-delete="deleteImage"
						/>
					</template>
				</van-field>
				<van-field
					name="uploader"
					label="身份证国徽面照片"
					required
					:rules="[{ required: true, message: '请上传身份证国徽面照片' }]"
				>
					<template #input>
						<van-uploader
							v-model="userEntryImage.idCardBackImageUploader"
							:before-read="imageUpload"
							@click-upload="flagUpload = true"
							name="idCardBackImageUploader"
							:before-delete="deleteImage"
						/>
					</template>
				</van-field>
				<van-field name="uploader" label="电工证照片" v-show="userEntryAddParams.job === '0'">
					<template #input>
						<van-uploader
							v-model="userEntryImage.electricianImageUploader"
							:before-read="imageUpload"
							@click-upload="flagUpload = true"
							name="electricianImageUploader"
							:before-delete="deleteImage"
						/>
					</template>
				</van-field>
				<van-field name="uploader" label="银行卡照片">
					<template #input>
						<van-uploader
							v-model="userEntryImage.bankCardImageUploader"
							:before-read="imageUpload"
							@click-upload="flagUpload = true"
							name="bankCardImageUploader"
							:before-delete="deleteImage"
						/>
					</template>
				</van-field>
				<van-field name="uploader" label="自拍照">
					<template #input>
						<van-uploader
							v-model="userEntryImage.selfImageUploader"
							:before-read="imageUpload"
							@click-upload="flagUpload = true"
							name="selfImageUploader"
							:before-delete="deleteImage"
						/>
					</template>
				</van-field>
			</div>
			<div class="bottom">
				<van-button round block type="info" native-type="submit">
					提&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;交
				</van-button>
			</div>

			<van-popup v-model="showSex" position="bottom">
				<van-picker
					value-key="label"
					show-toolbar
					:columns="sexChoiceList"
					@confirm="onSexPickUp"
					@cancel="showSex = false"
				/>
			</van-popup>

			<van-popup v-model="showJob" position="bottom">
				<van-picker
					value-key="label"
					show-toolbar
					:columns="jobChoiceList"
					@confirm="onJobPickUp"
					@cancel="showJob = false"
				/>
			</van-popup>

			<van-popup v-model="showTime" position="bottom">
				<van-datetime-picker
					type="date"
					title="请选择日期"
					v-model="currentDate"
					@confirm="onTime"
					@cancel="showTime = false"
				/>
			</van-popup>
		</van-form>
	</div>
</template>

<script>
	import { Toast } from 'vant';
	import moment from 'moment';
	import ImageCompressor from 'compressorjs';

	export default {
		data() {
			return {
				showLoading: false,
				showTime: false,
				showSex: false,
				showJob: false,
				flagUpload: true,
				currentDate: '',
				userEntryAddParams: {
					name: '',
					telephone: '',
					idCard: '',
					job: '',
					jobStr: '',
					electricianLicense: '',
					electricianExpDate: '',
					driverId: '',
					education: '',
					idCardFrontImageUrl: '',
					idCardBackImageUrl: '',
					electricianImageUrl: '',
					bankCardImageUrl: '',
					selfImageUrl: '',
				},
				userEntryImage: {
					idCardFrontImageUploader: [],
					idCardBackImageUploader: [],
					electricianImageUploader: [],
					bankCardImageUploader: [],
					selfImageUploader: [],
				},
				sexChoiceList: [
					{
						value: '男',
						label: '男',
					},
					{
						value: '女',
						label: '女',
					},
				],
				jobChoiceList: [
					{
						value: '0',
						label: '师傅',
					},
					{
						value: '1',
						label: '客服或其他',
					},
				],
			};
		},
		created() {},
		methods: {
			onLoad() {},
			validatePhone(value, rule) {
				console.log(rule);
				if (!value) {
					return true;
				} else {
					if (!new RegExp(/^1[0-9][0-9]\d{8}$/).test(value)) {
						Toast.fail('请输入正确格式的手机号码!');
						return false;
					}
					return true;
				}
			},
			validateIdCard(value, rule) {
				console.log(rule);
				if (!value) {
					return true;
				} else {
					if (
						!new RegExp(
							/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
						).test(value)
					) {
						Toast.fail('请输入正确格式的身份证号码!');
						return false;
					}
					return true;
				}
			},
			onSexPickUp(value) {
				this.userEntryAddParams.sex = value.value;
				this.showSex = false;
			},
			onJobPickUp(value) {
				this.userEntryAddParams.job = value.value;
				this.userEntryAddParams.jobStr = value.label;
				console.log(this.userEntryAddParams);
				this.showJob = false;
			},
			onTime(value) {
				this.userEntryAddParams.electricianExpDate = value.getTime();
				this.showTime = false;
			},
			idCardChange() {
				let year = this.userEntryAddParams.idCard.substring(6, 10);
				let date = new Date();
				let nowYear = date.getFullYear();
				let age = nowYear - year;
				if (age > 0 && age <= 150) {
					this.userEntryAddParams.age = age;
					this.$forceUpdate();
				}

				let sexNumber = this.userEntryAddParams.idCard.substring(16, 17);
				if (sexNumber % 2 == 0) {
					this.userEntryAddParams.sex = '女';
					this.$forceUpdate();
				} else if (sexNumber % 2 == 1) {
					this.userEntryAddParams.sex = '男';
					this.$forceUpdate();
				}
			},
			onSubmit() {
				this.axios({
					method: 'post',
					url: '/v1/web/user-entry/add-user-entry',
					data: this.userEntryAddParams,
				}).then((res) => {
					if (res.data.code === 200) {
						let url =
							window.location.href.replace(window.location.pathname, '/EntryAdd/Success') +
							'?entryid=' +
							res.data.data;
						const appId = 'wx3eb9ede59b40d88a';
						let href =
							'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
							appId +
							'&redirect_uri=' +
							encodeURIComponent(url) +
							'&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect'; //打开这个链接，你的url后面就会跟上code的参数
						window.location.href = href;
					} else {
						Toast.fail(res.data.message);
					}
				});
			},
			imageUpload(file, detail) {
				this.clickUpload(file, detail.name);
			},
			async clickUpload(file, name) {
				this.showLoading = true;
				let maxSize = 200 * 1024; //自己定义的文件大小，超过多少M就开始压缩(现在是200k)
				if (file.size > maxSize) {
					file = await this.compressFile(file);
				}
				this.uploadImage(file, name);
			},
			/**
			 * 压缩图片
			 * @param file
			 * @returns {Promise<unknown>}
			 */
			compressFile(file) {
				return new Promise((resolve, reject) => {
					new ImageCompressor(file, {
						quality: 0.2, //压缩质量
						checkOrientation: false, //图片翻转，默认为false
						success(result) {
							resolve(result);
						},
						error(e) {
							reject(e);
						},
					});
				});
			},
			uploadImage(file, name) {
				let param = new FormData(); //创建form对象
				param.append('files', file);
				this.axios({
					method: 'post',
					url: '/v1/web/user-entry/image-upload',
					data: param,
				})
					.then((res) => {
						this.handlePreviewImage(res.data.data.imageUrl, name);
						this.flagUpload = true;
						this.showLoading = false;
					})
					.catch(() => {
						Toast.fail('上传失败');
						this.showLoading = false;
					});
			},
			handlePreviewImage(imgUrl, name) {
				if (imgUrl === undefined || imgUrl === null || imgUrl === 'deleteImage') {
					return;
				}

				let imageObj = {};
				imageObj.url = '/api/v1/web/order-info/preview-image?path=' + imgUrl;
				switch (name) {
					case 'idCardFrontImageUploader':
						this.userEntryImage.idCardFrontImageUploader = [];
						this.userEntryImage.idCardFrontImageUploader.push(imageObj);
						this.userEntryAddParams.idCardFrontImageUrl = imgUrl;
						break;
					case 'idCardBackImageUploader':
						this.userEntryImage.idCardBackImageUploader = [];
						this.userEntryImage.idCardBackImageUploader.push(imageObj);
						this.userEntryAddParams.idCardBackImageUrl = imgUrl;
						break;
					case 'electricianImageUploader':
						this.userEntryImage.electricianImageUploader = [];
						this.userEntryImage.electricianImageUploader.push(imageObj);
						this.userEntryAddParams.electricianImageUrl = imgUrl;
						break;
					case 'bankCardImageUploader':
						this.userEntryImage.bankCardImageUploader = [];
						this.userEntryImage.bankCardImageUploader.push(imageObj);
						this.userEntryAddParams.bankCardImageUrl = imgUrl;
						break;
					case 'selfImageUploader':
						this.userEntryImage.selfImageUploader = [];
						this.userEntryImage.selfImageUploader.push(imageObj);
						this.userEntryAddParams.selfImageUrl = imgUrl;
						break;
				}
			},
			deleteImage(file, detail) {
				switch (detail.name) {
					case 'idCardFrontImageUploader':
						this.userEntryImage.courierImageUploader = [];
						this.userEntryAddParams.idCardFrontImageUrl = '';
						break;
					case 'idCardBackImageUploader':
						this.userEntryImage.courierImageUploader = [];
						this.userEntryAddParams.idCardBackImageUrl = '';
						break;
					case 'electricianImageUploader':
						this.userEntryImage.courierImageUploader = [];
						this.userEntryAddParams.electricianImageUrl = '';
						break;
					case 'bankCardImageUploader':
						this.userEntryImage.courierImageUploader = [];
						this.userEntryAddParams.bankCardImageUrl = '';
						break;
				}
			},
			getImageUrlArray(imageObjs) {
				let resultStr = '';
				for (let i = 0; i < imageObjs.length; i++) {
					if (resultStr !== '') {
						resultStr = resultStr + ',';
					}
					resultStr = resultStr + imageObjs[i].url.substring(imageObjs[i].url.indexOf('=') + 1);
				}
				return resultStr;
			},
			formatTime(time) {
				if (time == null || time == '') {
					return '';
				}
				return moment(time).format('YYYY-MM-DD');
			},
		},
	};
</script>

<style scoped>
	.bottom {
		width: 95%;
		left: 0;
		right: 0;
		margin: auto;
		margin-bottom: 10px;
	}
	.van-form {
		background-color: white;
	}
	input,
	input:focus,
	textarea,
	textarea:focus,
	select,
	select:focus {
		font-size: 16px !important;
	}
</style>
